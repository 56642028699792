// load appropriate dotenv file
require('dotenv').config({
  path: `.env.${process.env.NODE_ENV}`,
})

const defaultHost = `http://localhost:8000`

module.exports = {
  siteMetadata: {
    siteUrl: process.env.GATSBY_SITE_URL || defaultHost,
    title: `Climate Health and Risk Tool`,
    shortTitle: `CHaRT`,
    description: `A tool to help explore climate and health risk models.`,
    author: `University of Washington, Center for Health and the Global Environment`,
    googleAnalyticsId: process.env.GATSBY_GOOGLE_ANALYTICS_ID,
    sentryDSN: process.env.GATSBY_SENTRY_DSN,
    contactEmail: `climatehealth@uw.edu`,
  },
  flags: {
    FAST_DEV: false,
    // DEV_SSR: false,
  },
  plugins: [
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        trackingIds: process.env.GATSBY_GOOGLE_ANALYTICS_ID
          ? [process.env.GATSBY_GOOGLE_ANALYTICS_ID]
          : [],
        gtagConfig: {
          anonymize_ip: true,
        },
        pluginConfig: {
          head: true,
          respectDNT: true,
        },
      },
    },
    {
      resolve: `gatsby-plugin-theme-ui`,
      options: {
        injectColorFlashScript: false,
      },
    },
    `gatsby-plugin-image`,
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images/`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `analysis_units`,
        path: `${__dirname}/../items/analysis_units`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `articles`,
        path: `${__dirname}/../items/articles`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `models`,
        path: `${__dirname}/../items/models`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `overlays`,
        path: `${__dirname}/../items/overlays`,
      },
    },
    `gatsby-plugin-catch-links`,
    `gatsby-plugin-sitemap`,
    {
      resolve: 'gatsby-plugin-robots-txt',
      options: {
        host: process.env.GATSBY_SITE_URL || `http://localhost:8000`,
      },
    },
  ],
}
