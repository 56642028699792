export default {
  useColorSchemeMediaQuery: false,
  useLocalStorage: false,
  breakpoints: ['40em', '52em', '64em'],
  colors: {
    background: '#fafafa', // from https://mdigi.tools/color-shades/#5e5555 set to 25 levels
    white: '#FFF',
    black: '#000',
    link: '#4B2E83', // '#0088dd',
    text: '#333',
    primary: '#4B2E83', // purple
    secondary: '#B7A57A', // gold
    highlight: '#FF815E',
    // palettes generated using https://mycolor.space/?hex=%234B2E83&sub=1
    // and https://maketintsandshades.com/#4B2E83
    blue: {
      2: '#0093BF',
      5: '#0075BB',
      8: '#0055A7',
    },

    purple: {
      1: '#dbd5e6',
      2: '#9382b5',
      3: '#816da8',
      5: '#9975D2',
      7: '#5d438f',
      8: '#4B2E83', // primary
      9: '#3c2569',
    },
    // from UW website palette
    gold: {
      0: '#f8f7f2',
      1: '#f4f1e9',
      2: '#e8e3d3',
    },
    // generated from desaturated primary using https://mdigi.tools/color-shades/#5e5555
    grey: {
      0: '#f3f2f2',
      1: '#dbd7d7',
      2: '#c2bcbc',
      3: '#aaa1a1',
      4: '#928686',
      5: '#796d6d',
      6: '#5e5555',
      7: '#433d3d',
      8: '#282424',
      9: '#0d0c0c',
    },
  },
  fonts: {
    body: '"Open Sans",Helvetica,Arial,sans-serif',
    heading: '"Encode Sans",Helvetica,Arial,sans-serif',
  },
  fontSizes: [12, 14, 16, 18, 24, 32, 48, 64, 72, 112],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.4,
    heading: 1.2,
  },
  layout: {
    container: {
      px: ['1rem', '1rem', '0px'],
      mt: '2rem',
      mb: '4rem',
    },
  },
  sizes: {
    container: '960px',
  },
  text: {
    default: {
      display: 'block', // fix for theme-ui v6 (div => span)
    },
    help: {
      display: 'block',
      color: 'grey.7',
      fontSize: 1,
    },
    paragraph: {
      fontSize: [2, 3],
      large: {
        fontSize: '1.25rem',
      },
    },
    heading: {
      color: 'primary',
      fontFamily: 'heading',
      fontWeight: 700,
      textTransform: 'uppercase',
      section: {
        fontSize: ['1.5rem', '2rem'],
        mb: '1.5rem',
      },
    },
  },
  buttons: {
    primary: {
      cursor: 'pointer',
      color: '#FFF',
      bg: 'primary',
      '&:hover': {
        bg: 'purple.7',
      },
      '& a': {
        color: '#FFF',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    disabled: {
      cursor: 'not-allowed',
      color: '#FFF',
      bg: 'grey.4',
    },
    secondary: {
      cursor: 'pointer',
      color: '#FFF',
      bg: 'grey.6',
      '&:hover': {
        bg: 'grey.5',
      },
    },
    close: {
      fontSize: 2,
      pr: '0.5rem',
      cursor: 'pointer',
      outline: 'none',
      background: 'none',
      color: 'grey.5',
      '&:hover': { color: 'grey.9' },
    },
  },
  boxes: {
    section: {
      '&:not(:first-of-type)': {
        mt: '6rem',
      },
    },
  },
  images: {
    basemap: {
      border: '2px solid #FFF',
      boxShadow: '0 1px 5px rgba(0,0,0,0.65)',
      m: 0,
      width: '64px',
      height: '64px',
      borderRadius: '64px',
      '&:hover': {
        boxShadow: '0 1px 5px rgba(0,0,0,1)',
        borderColor: '#EEE',
      },
    },
    'basemap-active': {
      border: '2px solid',
      borderColor: 'highlight',
      boxShadow: '0 1px 5px rgba(0,0,0,0.65)',
      m: 0,
      width: '64px',
      height: '64px',
      borderRadius: '64px',
      '&:hover': {
        boxShadow: '0 1px 5px rgba(0,0,0,1)',
        borderColor: '#EEE',
      },
    },
  },
  styles: {
    root: {
      height: '100vh',
      overflowX: 'hidden',
      overflowY: 'hidden',
      margin: 0,
      body: {
        margin: 0,
        height: '100%',
        width: '100%',
        position: 'relative',
        zIndex: 0,
        bg: 'background',
      },
      '#___gatsby': {
        height: '100%',
      },
      '#___gatsby > *': {
        height: '100%',
      },
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      h1: {
        variant: 'text.heading',
        fontSize: [5, 6],

        fontWeight: '900 !important',
      },
      h2: {
        variant: 'text.heading',
        fontSize: [4, 5],
      },
      h3: {
        variant: 'text.heading',
        fontSize: [3, 4],
      },
      h4: {
        variant: 'text.heading',
        fontSize: [2, 3],
      },
      a: {
        color: 'link',
        fontWeight: 600,
        transition: 'color .3s ease-in-out',
        '&:hover': {
          color: '#85754d',
        },
      },
      ul: {
        pl: '1.25em',
        'li + li': {
          mt: '0.5em',
        },
      },
    },
    hr: {
      borderBottom: '2px solid',
      borderBottomColor: 'secondary',
      my: '2rem',
    },
  },
}
