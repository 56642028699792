exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-articles-geographies-index-jsx": () => import("./../../../src/pages/articles/geographies/index.jsx" /* webpackChunkName: "component---src-pages-articles-geographies-index-jsx" */),
  "component---src-pages-articles-index-jsx": () => import("./../../../src/pages/articles/index.jsx" /* webpackChunkName: "component---src-pages-articles-index-jsx" */),
  "component---src-pages-articles-topics-index-jsx": () => import("./../../../src/pages/articles/topics/index.jsx" /* webpackChunkName: "component---src-pages-articles-topics-index-jsx" */),
  "component---src-pages-fuzzy-modeling-101-index-jsx": () => import("./../../../src/pages/fuzzy-modeling-101/index.jsx" /* webpackChunkName: "component---src-pages-fuzzy-modeling-101-index-jsx" */),
  "component---src-pages-how-to-use-chart-index-jsx": () => import("./../../../src/pages/how-to-use-chart/index.jsx" /* webpackChunkName: "component---src-pages-how-to-use-chart-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-models-geographies-index-jsx": () => import("./../../../src/pages/models/geographies/index.jsx" /* webpackChunkName: "component---src-pages-models-geographies-index-jsx" */),
  "component---src-pages-models-index-jsx": () => import("./../../../src/pages/models/index.jsx" /* webpackChunkName: "component---src-pages-models-index-jsx" */),
  "component---src-pages-models-topics-index-jsx": () => import("./../../../src/pages/models/topics/index.jsx" /* webpackChunkName: "component---src-pages-models-topics-index-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-templates-article-page-jsx": () => import("./../../../src/templates/ArticlePage.jsx" /* webpackChunkName: "component---src-templates-article-page-jsx" */),
  "component---src-templates-geography-articles-page-jsx": () => import("./../../../src/templates/GeographyArticlesPage.jsx" /* webpackChunkName: "component---src-templates-geography-articles-page-jsx" */),
  "component---src-templates-geography-models-page-jsx": () => import("./../../../src/templates/GeographyModelsPage.jsx" /* webpackChunkName: "component---src-templates-geography-models-page-jsx" */),
  "component---src-templates-model-page-jsx": () => import("./../../../src/templates/ModelPage.jsx" /* webpackChunkName: "component---src-templates-model-page-jsx" */),
  "component---src-templates-topic-articles-page-jsx": () => import("./../../../src/templates/TopicArticlesPage.jsx" /* webpackChunkName: "component---src-templates-topic-articles-page-jsx" */),
  "component---src-templates-topic-models-page-jsx": () => import("./../../../src/templates/TopicModelsPage.jsx" /* webpackChunkName: "component---src-templates-topic-models-page-jsx" */)
}

